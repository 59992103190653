import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { GlobalsService } from "./utils/globals.service";
import { ILoginModel } from "@models";

@Injectable()
export class LoginService {
	readonly NUM_BACKGROUND_IMGS: number = 9;
	static imgName: string;

	constructor(private httpService: HttpService) { }

	async tryLogin(loginModel: ILoginModel, loginType: string): Promise<string> {
		loginModel.version = GlobalsService.appVersion;
		return this.httpService.post(`/login/validateLogin?loginType=${loginType}`, loginModel);
	}

	async logout(logoutType: string) {
		return this.httpService.get(`/login/logout?logoutType=${logoutType}`);
	}

	async autoLogout() {
		return this.httpService.get("/login/autoLogout");
	}

	async getResetPasswordUser(userIdentifier: string) {
		return this.httpService.get(`/login/getResetPasswordUser?userIdentifier=${userIdentifier}`, {});
	}

	async sendResetPassword(emailAddress: string): Promise<boolean> {
		return this.httpService.post(`/login/sendResetPassword?email=${emailAddress}`, {});
	}

	async resetPasswordWithToken(token: string, password: string): Promise<boolean> {
		return this.httpService.post(`/login/resetPasswordChange?token=${token}`, { password });
	}

	async verifyPasswordComplexity(password: string): Promise<boolean> {
		const params = { password };
		return this.httpService.get("/login/verifyPasswordComplexity", params);
	}

	getBackgroundImgName(): string {
		if (LoginService.imgName)
			return LoginService.imgName;

		let imgNumStr = localStorage.getItem("LOGIN_BACKGOUND_IMG_NUM");
		if (!imgNumStr)
			imgNumStr = "0";

		let imgNum: number = parseInt(imgNumStr);
		imgNum++;
		if (imgNum > this.NUM_BACKGROUND_IMGS)
			imgNum = 1;

		imgNumStr = imgNum.toString();
		localStorage.setItem("LOGIN_BACKGOUND_IMG_NUM", imgNumStr);

		LoginService.imgName = 'background' + imgNum + '.jpg';

		return LoginService.imgName;
	}
}