import { UtilsService } from "@services";
import { IAppliedPaymentModel } from "../payments/applied-payment.model";
import { IInvoiceCreditLineItemModel } from "./invoice-credit-line-item.model";
import { IInvoiceLineItemModel } from "./invoice-line-item.model";
import * as moment from 'moment';

export interface IInvoiceModel {
	invoiceId: number;
	uuid: string;
	version: number;
	jobId?: number;
	invoiceNumber: string;
	poNumber: string;
	invoiceDate?: Date;
	dueDate?: Date;
	description: string;
	notes: string;
	printoutNotes: string;
	documentFolderUuid: string;
	photosFolderUuid: string;
	lineItemsTotal: number;
	creditLineItemsTotal: number;
	invoiceTotal: number;
	appliedPaymentsTotal: number;
	creditCardFeesTotal: number;
	amountDue: number;
	creditCardFee: number;
	waiveCreditCardFee: boolean;
	isDeleted: boolean;

	invoiceLineItems: IInvoiceLineItemModel[];
	invoiceCreditLineItems: IInvoiceLineItemModel[];
	appliedPayments: IAppliedPaymentModel[];
}

export class InvoiceModel implements IInvoiceModel {
	constructor() {
		this.invoiceId = 0;
		this.uuid = UtilsService.newGuid();
		this.version = 1;
		this.documentFolderUuid = UtilsService.newGuid();
		this.photosFolderUuid = UtilsService.newGuid();
		this.lineItemsTotal = 0;
		this.creditLineItemsTotal = 0;
		this.invoiceTotal = 0;
		this.appliedPaymentsTotal = 0;
		this.amountDue = 0;
		this.invoiceDate = moment().startOf("day").toDate();

		this.invoiceLineItems = [];
		this.invoiceCreditLineItems = [];
		this.appliedPayments = [];
	}

	invoiceId: number;
	uuid: string;
	version: number;
	jobId?: number;
	invoiceNumber: string;
	poNumber: string;
	invoiceDate?: Date;
	dueDate?: Date;
	description: string;
	notes: string;
	printoutNotes: string;
	documentFolderUuid: string;
	photosFolderUuid: string;
	lineItemsTotal: number;
	creditLineItemsTotal: number;
	invoiceTotal: number;
	appliedPaymentsTotal: number;
	creditCardFeesTotal: number;
	amountDue: number;
	creditCardFee: number;
	waiveCreditCardFee: boolean;
	isDeleted: boolean;

	invoiceLineItems: IInvoiceLineItemModel[];
	invoiceCreditLineItems: IInvoiceCreditLineItemModel[];
	appliedPayments: IAppliedPaymentModel[];
}