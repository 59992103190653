import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { GlobalsService, AuthService, LoginService, SleepService, UtilsService } from "@services";
import { PayablesStore, TextChatStore, TasksStore } from "@stores";
import { Subscription } from "rxjs";

@Component({
	selector: "side-menu",
	templateUrl: "side-menu.component.html",
	styleUrls: ['side-menu.component.css']
})

export class SideMenuComponent implements OnInit, OnDestroy, AfterViewInit {
	firstName: string;
	profilePictureUrl: string;
	canAccessJobs: boolean;
	canAccessTasks: boolean;
	canAccessSchedule: boolean;
	canAccessCustomers: boolean;
	canAccessVendors: boolean;
	canAccessSubcontractor: boolean;
	canAccessCommissions: boolean;
	canAccessBusinessDevelopment: boolean;
	canAccessEquipment: boolean;
	canAccessCompanySettings: boolean;
	canAccessTimeclock: boolean;
	canAccessInventory: boolean;
	canAccessUsers: boolean;
	canAccessSetup: boolean;
	canAccessQuickbooks: boolean;
	canAccessPayments: boolean = false;
	canAccessPayables: boolean = false;
	canAccessTextChat: boolean = false;
	canAccessTeams: boolean = false;
	canAccessReporting: boolean = false;
	canAccessWorkOrders: boolean = false;
	canAccessEmailLogs: boolean = false;
	canAccessGlobalAdmin: boolean = (GlobalsService.userInfo.isGlobalAdmin)
	isLoggedIn: boolean;

	payablesAwaitingApprovalCount: number;
	payablesAwaitingApprovalCount$: Subscription;

	taskCount: number;
	taskCount$: Subscription;

	textChatUnreadMessagesCount: number;
	textChatUnreadMessageCount$: Subscription;

	useQuickbooksDesktop: boolean = GlobalsService.companySettings.useQuickbooksDesktop;

	isMenuExpanded: boolean = (localStorage.getItem("menuState") || 'expanded') === 'expanded';

	constructor(private authService: AuthService,
		private loginService: LoginService,
		private payablesStore: PayablesStore,
		private textChatStore: TextChatStore,
		private tasksStore: TasksStore) {
		this.isLoggedIn = GlobalsService.isLoggedIn;

		if (this.isLoggedIn) {
			const employeeType = GlobalsService.userInfo.employeeType;
			this.canAccessJobs = GlobalsService.checkPermission("Jobs", "View");
			this.canAccessTasks = GlobalsService.checkPermission("Tasks", "View");
			this.canAccessSchedule = GlobalsService.checkPermission("Appointments", "View");
			this.canAccessCustomers = GlobalsService.checkPermission("Customers", "View");
			this.canAccessVendors = GlobalsService.checkPermission("Vendors", "View");
			this.canAccessSubcontractor = true;
			this.canAccessBusinessDevelopment = GlobalsService.checkPermission("BusinessDevelopment", "Access");
			this.canAccessCommissions = GlobalsService.checkPermission("Commissions", "View");
			this.canAccessEquipment = GlobalsService.checkPermission("Equipment", "View");
			this.canAccessPayments = GlobalsService.checkPermission("Payments", "View");
			this.canAccessTimeclock = GlobalsService.checkPermission("Timeclock", "Access");
			this.canAccessPayables = GlobalsService.checkPermission("Payables", "View");
			this.canAccessInventory = GlobalsService.checkPermission("InventoryItems", "View");
			this.canAccessCompanySettings = GlobalsService.checkPermission("CompanySettings", "Access");
			this.canAccessUsers = GlobalsService.checkPermission("Users", "Create|Update|View");
			this.canAccessSetup = GlobalsService.checkPermission("Setup", "Access");
			this.canAccessQuickbooks = GlobalsService.checkPermission("Quickbooks", "Access");
			this.canAccessTextChat = GlobalsService.companySettings.useTextChat;
			this.canAccessTeams = GlobalsService.checkPermission("Teams", "Create|Update|Delete");
			this.canAccessReporting = GlobalsService.checkPermission("Reporting", "Access");
			this.canAccessWorkOrders = GlobalsService.checkPermission("WorkOrders", "View");
			this.canAccessEmailLogs = GlobalsService.checkPermission("EmailLogs", "Access");
			this.firstName = GlobalsService.userInfo.firstName;
			this.profilePictureUrl = GlobalsService.userInfo.profilePictureUrl;
		}
	}

	ngOnInit() {
		if (GlobalsService.checkPermission("Payables", "View") && GlobalsService.checkPermission("Payables", "CanSeeAwaitingApproval")) {
			this.payablesAwaitingApprovalCount$ = this.payablesStore.payablesAwaitingApprovalCountSubject.subscribe(payablesAwaitingApprovalCount => this.payablesAwaitingApprovalCount = payablesAwaitingApprovalCount);
		}

		this.taskCount$ = this.tasksStore.taskCountSubject.subscribe(taskCount => this.taskCount = taskCount);

		if (GlobalsService.companySettings.useTextChat) {
			this.textChatUnreadMessageCount$ = this.textChatStore.unreadMessagesCount$.subscribe(textChatUnreadMessagesCount => {
				this.textChatUnreadMessagesCount = textChatUnreadMessagesCount;
			});
		}

	}

	ngOnDestroy() {
		if (this.payablesAwaitingApprovalCount$)
			this.payablesAwaitingApprovalCount$.unsubscribe();
	}

	ngAfterViewInit() {
		if (this.isMenuExpanded)
			document.getElementById("main-area-container").classList.add('menu-is-open');
	}

	toggleExpanded() {
		this.isMenuExpanded = !this.isMenuExpanded;
		document.getElementById("main-area-container").classList.toggle('menu-is-open');
		localStorage.setItem("menuState", this.isMenuExpanded ? 'expanded' : 'collapsed');
	}

	async logout() {
		await this.loginService.logout("desktop");
		this.authService.clearToken();
		window.location.href = '/';
	}
}