import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { IAppliedPaymentJobInvoiceSummaryModel, IAppliedPaymentModel, IAppliedPaymentSummaryModel, IEmailerModel, IInvoiceModel, IJobModel, IPaymentGridModel, IPaymentModel, PaymentModel } from "@models";
import { GlobalsService } from "./utils/globals.service";

@Injectable()
export class PaymentsService {
	constructor(private httpService: HttpService) {
	}

	async getPayment(paymentId: number): Promise<IPaymentModel> {
		return this.httpService.get("/payments/getPayment", { paymentId: paymentId });
	}

	async getPaymentForGrid(paymentId: number): Promise<IPaymentGridModel> {
		return this.httpService.get("/payments/getPaymentForGrid", { paymentId: paymentId });
	}

	async applyPayment(appliedPayment: IAppliedPaymentModel): Promise<IAppliedPaymentModel> {
		return this.httpService.post("/payments/applyPayment", appliedPayment);
	}

	async unapplyPayment(appliedPaymentUuid: string): Promise<void> {
		return this.httpService.delete(`/payments/unapplyPayment?appliedPaymentUuid=${appliedPaymentUuid}`);
	}

	async updateAppliedPayment(appliedPayment: IAppliedPaymentModel): Promise<IAppliedPaymentModel> {
		return this.httpService.post("/payments/updateAppliedPayment", appliedPayment);
	}

	async getAppliedPayment(appliedPaymentId: number): Promise<IAppliedPaymentModel> {
		return this.httpService.get(`/payments/getAppliedPayment`, { appliedPaymentId: appliedPaymentId })
	}

	async getAppliedPaymentsForJob(jobId: number): Promise<IAppliedPaymentSummaryModel[]> {
		return this.httpService.get("/payments/getAppliedPaymentsForJob", { jobId: jobId });
	}

	async getAppliedPaymentsForInvoice(invoiceId: number): Promise<IAppliedPaymentSummaryModel[]> {
		return this.httpService.get("/payments/getAppliedPaymentsForInvoice", { invoiceId: invoiceId });
	}

	async getAppliedPaymentsForPayment(paymentId: number): Promise<IAppliedPaymentSummaryModel[]> {
		return this.httpService.get("/payments/getAppliedPaymentsForPayment", { paymentId: paymentId });
	}

	async getUnappliedPayments(searchString: string): Promise<IPaymentModel[]> {
		return this.httpService.get("/payments/getUnappliedPayments", { searchString: searchString });
	}

	async getAppliedPaymentJobInvoiceSummariesByBillingId(customerId: number): Promise<IAppliedPaymentJobInvoiceSummaryModel[]> {
		return this.httpService.get(`/payments/getAppliedPaymentJobInvoiceSummariesByBillingId?customerId=${customerId}`);
	}

	async getAppliedPaymentJobInvoiceSummariesBySearchString(searchString: string): Promise<IAppliedPaymentJobInvoiceSummaryModel[]> {
		return this.httpService.get(`/payments/getAppliedPaymentJobInvoiceSummariesBySearchString?searchString=${searchString}`);
	}

	async getPaymentsByBillingId(customerId: number): Promise<IPaymentModel[]> {
		return this.httpService.get(`/payments/getPaymentsByBillingId?customerId=${customerId}`);
	}

	async getPaymentsBySearchString(searchString: string): Promise<IPaymentModel[]> {
		return this.httpService.get(`/payments/getPaymentsBySearchString?searchString=${searchString}`);
	}

	async getUnappliedPaymentsForCustomer(customerId: number): Promise<IPaymentModel[]> {
		return this.httpService.get("/payments/getUnappliedPaymentsForCustomer", { customerId: customerId });
	}

	async updatePayment(paymentModel: IPaymentModel): Promise<IPaymentModel> {
			if (paymentModel.paymentId === 0) {
				return this.httpService.post("/payments/addPayment", paymentModel);
			}
			else {
				return this.httpService.post("/payments/updatePayment", paymentModel);
			}
	}

	async deletePayment(paymentId: number, uuid: string): Promise<boolean> {
		return this.httpService.delete("/payments/deletePayment", { paymentId: paymentId, uuid: uuid });
	}

	async generatePaymentPDF(paymentModel: IPaymentModel): Promise<string> {
		const response = await this.httpService.get(`/payments/generatePaymentPDF?paymentId=${paymentModel.paymentId}`);
		return response.url;
	}

	async getPaymentEmail(paymentModel: IPaymentModel): Promise<IEmailerModel> {
		return this.httpService.get(`/payments/getPaymentEmail?paymentId=${paymentModel.paymentId}`);
	}

	async sendPaymentEmail(paymentId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {
		return this.httpService.post(`/payments/sendPaymentEmail?paymentId=${paymentId}`, emailerModel);
	}

	async generateAppliedPaymentPDF(appliedPaymentSummaryModel: IAppliedPaymentSummaryModel): Promise<string> {
		const response = await this.httpService.get(`/payments/generateAppliedPaymentPDF?appliedPaymentId=${appliedPaymentSummaryModel.appliedPaymentId}`);
		return response.url;
	}

	async getAppliedPaymentEmail(appliedPaymentSummaryModel: IAppliedPaymentSummaryModel): Promise<IEmailerModel> {
		return this.httpService.get(`/payments/getAppliedPaymentEmail?appliedPaymentId=${appliedPaymentSummaryModel.appliedPaymentId}`);
	}

	async sendAppliedPaymentEmail(appliedPaymentId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {
		return this.httpService.post(`/payments/sendAppliedPaymentEmail?appliedPaymentId=${appliedPaymentId}`, emailerModel);
	}


	calcGrandTotal(paymentModels: IPaymentModel[]): number {
		let grandTotal = 0;
		if (paymentModels && paymentModels.length > 0) {
			paymentModels.forEach(e => {
				grandTotal += e.paymentAmount;
			})
		}

		grandTotal = UtilsService.round(grandTotal);

		return grandTotal;
	}
}

