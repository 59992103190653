import { Injectable } from "@angular/core";
import { HttpService } from "../utils/http.service";
import { ICreditCardPaymentTypeModel, ICreditCardProcessPaymentModel, ICreditCardProcessPaymentResult } from "@models";
import { ICreditCardService } from "./credit-card.service";

@Injectable()
export class ZiftService implements ICreditCardService {

    constructor(private httpService: HttpService) { }

    getPaymentService(): string {
        return "Zift";
    }

    async getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.get("/zift/getNewCreditCardPaymentType", { customerId: customerId });
    }

    async getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.get("/zift/getCreditCardPaymentType", { creditCardPaymentTypeId: creditCardPaymentTypeId });
    }

    async getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]> {
        return await this.httpService.get("/zift/getCreditCardPaymentTypeForCustomer", { customerId: customerId });
    }

    async addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/zift/addCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/zift/updateCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/zift/deleteCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async processPrePayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return await this.httpService.post("/zift/processPrePayment", processCreditCardPaymentModel);
    }

    async processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return await this.httpService.post("/zift/processPayment", processCreditCardPaymentModel);
    }

    async processOnlinePayment(companyId: number, creditCardPaymentTypeModel: ICreditCardPaymentTypeModel, creditCardProcessPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> { 
        const params = {
            creditCardPaymentTypeModel: creditCardPaymentTypeModel,
            creditCardProcessPaymentModel: creditCardProcessPaymentModel
        }
        return await this.httpService.post(`/zift/processOnlinePayment?companyId=${companyId}`, params);
    }
}