import { Injectable } from "@angular/core";
import { EmailerAddressModel, EmailerModel, IEmailerModel, ITaskGridModel, ITaskModel } from "@models";
import { HttpService, LookupService, UtilsService } from "@services";
import * as moment from 'moment';

@Injectable()
export class TasksService {
	constructor(private httpService: HttpService,
		private lookupService: LookupService) { }

	async getTask(taskId: number): Promise<ITaskModel> {
		return this.httpService.get(`/tasks/getTask`, { taskId: taskId });
	}

	async getTaskForGrid(taskId: number): Promise<ITaskGridModel> {
		return this.httpService.get(`/tasks/getTaskForGrid`, { taskId: taskId });
	}

	async addTask(taskModel: ITaskModel): Promise<ITaskModel> {
		return this.httpService.post(`/tasks/addTask`, taskModel);
	}

	async updateTask(taskModel: ITaskModel): Promise<ITaskModel> {
		return this.httpService.post(`/tasks/updateTask`, taskModel);
	}

	async deleteTask(taskId: number): Promise<boolean> {
		return this.httpService.delete(`/tasks/deleteTask`, { taskId: taskId });
	}

	async getAllTasksForUser(userId: number, includeCompleted: boolean = false): Promise<ITaskModel[]> {
		const params = {
			userId: userId,
			includeCompleted: includeCompleted
		}
		return this.httpService.get(`/tasks/getAllTasksForUser`, params);
	}

	async getEmailerModel(taskModel: ITaskModel): Promise<IEmailerModel> {
		const emailerModel = new EmailerModel();
		emailerModel.uuid = UtilsService.newGuid();
		emailerModel.subject = `New task has been assigned to you by ${taskModel.ownerFullName}`;
		emailerModel.body = '';

		emailerModel.body += `
<div style='margin-bottom: 20px; font-size: 22px;'>New task</div>
${taskModel.title}<br />
<br />`;

		if (taskModel.description)
			emailerModel.body += `${taskModel.description}<br /><br />`;

		if (taskModel.dueDate)
			emailerModel.body += `Due: ${moment(taskModel.dueDate).format("M/D/YYYY h:mm a")}`;

		emailerModel.toEmailAddresses = (await this.lookupService.getEmployees())
			.filter(user => taskModel.assignedToUserIds.find(y => user.userId === y && (user.emailAddress ?? '') !== ''))
			.map(user => {
				const emailerAddress = new EmailerAddressModel();
				emailerAddress.contactId = user.contactId;
				emailerAddress.displayName = user.displayName;
				emailerAddress.emailAddress = user.emailAddress;
				return emailerAddress;
			});

		return emailerModel;

	}
}
