import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { GlobalsService } from "@services";
import { Router } from "@angular/router";

@Component({
	selector: "landing",
	template: ``
})
export class LandingComponent implements OnInit {
	constructor(private location: Location, private router: Router) { }

	ngOnInit() {
		if (GlobalsService.isLoggedIn === true) {
			this.router.navigate(["/jobs"]);
		//	if (GlobalsService.companyInfo.companyId === 1)
		//		this.router.navigate(["/dashboard"]);
		//	else
		//		this.router.navigate(["/jobs"]);
		}			
	}
}