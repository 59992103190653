import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ICommissionModel, ICommissionGridModel, ICommissionSettingsModel  } from "@models";

@Injectable() 
export class CommissionsService {
	constructor(private httpService: HttpService) {

	}

	async getCommission(commissionId: number): Promise<ICommissionModel> {
		return this.httpService.get("/commissions/getCommission", { commissionId });
	}

	async updateCommission(commission: ICommissionModel): Promise<ICommissionModel> {
		return this.httpService.post("/commissions/updateCommission", commission);
	}

	async getCommissionForGrid(commissionId: number): Promise<ICommissionGridModel> {
		return this.httpService.get("/commissions/getCommissionForGrid", { commissionId });
	}

	async setCommissionAsPaid(commissionModel: ICommissionModel): Promise<ICommissionModel> {
		return this.httpService.post("/commissions/setCommissionAsPaid", commissionModel);
	}

	async getCommissionSettings(): Promise<ICommissionSettingsModel> {
		return this.httpService.get("/commissions/getCommissionSettings");
	}

	async updateCommissionSettings(commissionSettings: ICommissionSettingsModel): Promise<ICommissionSettingsModel> {
		return this.httpService.post("/commissions/updateCommissionSettings", commissionSettings);
	}
}
