import { ContactModel, IContactModel } from "@models/contacts/contact.model";

export interface ICustomerModel {
	customerId: number;
	uuid: string;
	version: number;
	cardOnFile: string;
	textOptIn: boolean;
	emailOptIn: boolean;
	active: boolean;

	contact: IContactModel;
}

export class CustomerModel implements ICustomerModel {
	constructor() {
		this.contact = new ContactModel();
	}

	customerId: number;
	uuid: string;
	version: number;
	cardOnFile: string;
	textOptIn: boolean;
	emailOptIn: boolean;
	active: boolean;

	contact: IContactModel;
}
