import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from "@angular/core";
import { IContactModel } from "@models";
import { SleepService, ContactsService, ConcurrencyValidationService, GlobalsService } from "@services";
import { ConcurrencyErrorDialogComponent } from "@components";

@Component({
	selector: "contact-edit",
	templateUrl: "contact-edit.component.html"
})

export class ContactEditComponent implements OnChanges {
	@Input() contactModel: IContactModel;
	@Input() hideFirstAndLastName: boolean = false;
	@Input() hideCompanyName: boolean = false;
	@Input() companyLabel: string = "Company Name";
	@Input() showQuickbooksName: boolean = false;
	@Input() showButtons: boolean = true;
	@Output() onSave: EventEmitter<IContactModel> = new EventEmitter<IContactModel>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("concurrencyErrorDialogRef", { static: true }) concurrencyErrorDialogRef: ConcurrencyErrorDialogComponent;

	useQuickbooks = GlobalsService.companySettings.useQuickbooks;

	contactTypeId: number;
	countries: any[];
	addressTypes: any[];
	phoneNumberTypes: any[];
	emailTypes: any[];
	spinnerStatus: string;
	invalidName: boolean = false;
	isSubmitted: boolean = false;
	concurrencyError: string;

	constructor(private contactsService: ContactsService, private concurrencyValidationService: ConcurrencyValidationService) {
	}

	ngOnChanges(changes: SimpleChanges) {
		this.isSubmitted = false;
		this.spinnerStatus = "reset";

		this.invalidName = false;

		if (changes.hideFirstAndLast)
			this.hideFirstAndLastName = (this.hideFirstAndLastName.toString().toLowerCase() === 'false') ? false : true;

		if (changes.hideCompanyName)
			this.hideCompanyName = (this.hideCompanyName.toString().toLowerCase() === 'false') ? false : true;

		if (changes.showQuickbooksName) 
			this.showQuickbooksName = (this.showQuickbooksName.toString().toLowerCase() !== 'true') ? false : true;
		
		if (changes.showButtons)
			this.showButtons = (changes.showButtons.toString().toLowerCase() === 'true');


	}

	validate(): boolean {
		this.isSubmitted = true;

		let valid = true;
		this.invalidName = false;

		if (this.hideFirstAndLastName === false && this.hideCompanyName === false) {
			if ((!this.contactModel.firstName || !this.contactModel.lastName) && !this.contactModel.companyName) {
				this.invalidName = true;
				valid = false;
			}
		}
		else if (this.hideFirstAndLastName === true && this.hideCompanyName === false) {
			if (!this.contactModel.companyName) {
				this.invalidName = true;
				valid = false;
			}
		}
		if (this.hideFirstAndLastName === false && this.hideCompanyName === true) {
			if (!this.contactModel.firstName || !this.contactModel.lastName) {
				this.invalidName = true;
				valid = false;
			}
		}

		return valid;
	}

	async onSaveContact() {
		try {
			// Any validation needs to go here
			if (this.validate() === false)
				return;

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			let updatedModel: IContactModel = await this.contactsService.updateContact(this.contactModel);
			this.spinnerStatus = "ok";
			this.onSave.emit(updatedModel);
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}
	}

	onCancelContact() {
		this.onCancel.emit();
	}
}