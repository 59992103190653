import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, OnInit } from "@angular/core";
import { GlobalsService, SleepService, LookupService, ContactsService, CustomersService, JobsService, ConcurrencyValidationService, ConcurrencyRecordTypes } from "@services";
import { IJobModel, IDropdownModel, ICustomerModel, IContactModel, DropdownModel, ICustomerSearchListModel, ContactModel } from "@models";
import { ConcurrencyErrorDialogComponent } from "@components";
import { DuplicateCustomerDialogComponent } from "./duplicate-customer-dialog.component";
import { SlickConfirmDialogComponent, SlickDialogComponent } from 'slick-components';
import Swal from 'sweetalert2';

@Component({
	selector: "job-edit",
	templateUrl: "job-edit.component.html"
})
export class JobEditComponent implements OnChanges {
	@Input() jobModel: IJobModel;
	@Input() showButtons: boolean = true;
	@Output() onSave: EventEmitter<IJobModel> = new EventEmitter<IJobModel>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("concurrencyErrorDialogRef", { static: true }) concurrencyErrorDialogRef: ConcurrencyErrorDialogComponent;
	@ViewChild("duplicateCustomerDialogRef", { static: true }) duplicateCustomerDialogRef: DuplicateCustomerDialogComponent;
	@ViewChild("removeAllEquipmentConfirmRef", { static: true }) removeAllEquipmentConfirmRef: SlickConfirmDialogComponent;
	@ViewChild("existingCustomerDialogRef") existingCustomerDialogRef: SlickDialogComponent;

	jobUuid: string;
	customerTabIndex: number = 0;
	spinnerStatus: string;
	saveAndEmailSpinnerStatus: string;
	showAlt2: boolean = false;
	testVal: string = "";
	isSubmitted: boolean = false;
	jobStatusIdTemp: number;
	jobStatuses: IDropdownModel[];
	damageTypes: IDropdownModel[];
	estimators: IDropdownModel[];
	productionManagers: IDropdownModel[]
	specialtyManagers: IDropdownModel[]
	referrals: IDropdownModel[];
	insuranceCarriers: IDropdownModel[];
	insuranceAdjusters: IDropdownModel[];
	insuranceAgents: IDropdownModel[];
	showCompanyName: boolean = false;
	concurrencyError: string;
	showAddCustomer: boolean = false;
	showAddBilling: boolean = false;
	canEditJobName: boolean = GlobalsService.checkPermission("JobName", "Update");
	canUpdateQuickbooksName: boolean = false;
	canUpdateJobStatus: boolean = GlobalsService.checkPermission("JobStatus", "Update");
	canChangeClosed: boolean = GlobalsService.checkPermission("JobStatus", "CanChangeClosed");
	useJobCustomerSearch: boolean = GlobalsService.companySettings.useJobCustomerSearch;
	useQuickbooks: boolean = GlobalsService.companySettings.useQuickbooks;
	forceQBNameUnlockCount: number = 0;

	existingCustomers: ICustomerSearchListModel[];

	constructor(private globalsService: GlobalsService,
		private lookupService: LookupService,
		private jobsService: JobsService,
		private contactsService: ContactsService,
		private customersService: CustomersService,
		private concurrencyValidationService: ConcurrencyValidationService) {
			// Emily, McKena and Amy have access to change QB name
		this.canUpdateQuickbooksName = GlobalsService.userInfo.userId === 1 || GlobalsService.userInfo.userId === 113 || GlobalsService.userInfo.userId === 382 || GlobalsService.userInfo.userId === 120;
	}

	async ngOnChanges() {
		this.customerTabIndex = 0;
		this.isSubmitted = false;
		this.showAddCustomer = false;
		this.showAddBilling = false;
		this.showAlt2 = false
		this.jobUuid = null;

		await SleepService.sleep();

		if (this.jobModel) {
			const results = await Promise.all([
				this.lookupService.getJobStatusesForDropdown(this.jobModel.jobStatusId),
				this.lookupService.getDamageTypesForDropdown(this.jobModel.damageTypeId),
				this.lookupService.getEstimatorsForDropdown(this.jobModel.estimatorUserId),
				this.lookupService.getEmployees(this.jobModel.productionManagerUserId, false),
				this.lookupService.getEmployees(this.jobModel.specialtyManagerUserId, false),
				this.lookupService.getReferralsForDropdown(this.jobModel.referralUuid),
				this.lookupService.getInsuranceCarriersForDropdown(this.jobModel.insuranceCarrierId),
				this.lookupService.getInsuranceAdjustersForDropdown(this.jobModel.insuranceAdjusterId),
				this.lookupService.getInsuranceAgentsForDropdown(this.jobModel.insuranceAgentId)
			])

			if (this.jobModel.billingIsSameAsPrimary)
				this.jobModel.billingCustomer = null;

			this.jobStatuses = await results[0];
			this.damageTypes = await results[1];
			this.estimators = await results[2];
			this.productionManagers = results[3]
				.filter(x => x.isProductionManager === true)
				.map(x => new DropdownModel(x.userId, x.fullName));
			this.specialtyManagers = results[4]
				.filter(x => x.isSpecialtyManager === true)
				.map(x => new DropdownModel(x.userId, x.fullName));
			this.referrals = results[5];
			this.insuranceCarriers = results[6]
			this.insuranceAdjusters = results[7]
			this.insuranceAgents = results[8]

			this.jobStatusIdTemp = this.jobModel.jobStatusId;
			this.showCompanyName = (this.jobModel.primaryCustomer && !!this.jobModel.primaryCustomer.contact.companyName);

			this.canUpdateJobStatus = GlobalsService.checkPermission("JobStatus", "Update");
			if (this.jobModel.jobStatusDescription === 'Closed' && this.canChangeClosed === false)
				this.canUpdateJobStatus = false;
				
			this.jobModel.jobName = this.jobsService.formatJobName(this.jobModel);

			await SleepService.sleep();

			this.jobUuid = this.jobModel.uuid;
		}
	}

	async onAddPrimaryCustomer(customerSearchText: string) {
		this.jobModel.primaryCustomer = this.customersService.getNewCustomerModel();
		this.jobModel.primaryCustomer.contact.secondaryEmailAddress = this.contactsService.getNewEmailAddressModel();
		this.jobModel.primaryCustomer.contact.secondaryEmailAddress.isSecondary = true;

		if (customerSearchText) {
			const searchTextNames = customerSearchText.split(' ');
			this.jobModel.primaryCustomer.contact.firstName = searchTextNames[0];

			if (searchTextNames.length > 1) {
				searchTextNames.splice(0, 1);
				this.jobModel.primaryCustomer.contact.lastName = searchTextNames.join(' ');
			}

			await this.onNameChanged();
		}

		this.showAddCustomer = true;
	}

	async checkExistingCustomers() {
		await SleepService.sleep();

		const searchText = (this.jobModel.primaryCustomer.contact.firstName + ' ' + this.jobModel.primaryCustomer.contact.lastName).trim();
		this.existingCustomers = await this.customersService.getCustomerSearchResults(searchText);

		if (this.existingCustomers.length > 0) {
			this.existingCustomerDialogRef.showDialog();
		}
	}

	async existingCustomerSelect(customerId: number) {
		if (customerId > 0)
			this.jobModel.primaryCustomer = await this.customersService.getCustomer(customerId);

		this.existingCustomerDialogRef.hideDialog();
	}

	async setBillingSameAsPrimary() {
		await SleepService.sleep();

		if (this.jobModel.billingIsSameAsPrimary) 
			this.jobModel.billingCustomer = null;		
	}

	async onAddBillingCustomer(customerSearchText: string) {
		this.jobModel.billingCustomer = this.customersService.getNewCustomerModel();
		this.jobModel.billingCustomer.contact.secondaryEmailAddress = this.contactsService.getNewEmailAddressModel();
		this.jobModel.billingCustomer.contact.secondaryEmailAddress.isSecondary = true;

		if (customerSearchText) {
			const searchTextNames = customerSearchText.split(' ');
			this.jobModel.billingCustomer.contact.firstName = searchTextNames[0];

			if (searchTextNames.length > 1) {
				searchTextNames.splice(0, 1);
				this.jobModel.billingCustomer.contact.lastName = searchTextNames.join(' ');
			}
		}

		this.showAddBilling = true;
	}


	async onNameChanged() {
		// Only update the job name if it's a new job
		if (this.jobModel.jobId > 0)
			return;

		await SleepService.sleep();
		if (this.jobModel.primaryCustomer.contact.firstName)
			this.jobModel.primaryCustomer.contact.firstName = this.jobModel.primaryCustomer.contact.firstName[0].toUpperCase() + this.jobModel.primaryCustomer.contact.firstName.substring(1);
		if (this.jobModel.primaryCustomer.contact.lastName)
			this.jobModel.primaryCustomer.contact.lastName = this.jobModel.primaryCustomer.contact.lastName[0].toUpperCase() + this.jobModel.primaryCustomer.contact.lastName.substring(1);

		this.jobModel.jobName = this.jobsService.formatJobName(this.jobModel);
	}

	onPrimaryCustomerSelect(customerModel: ICustomerModel) {
		// Only update the job name if it's a new job
		if (this.jobModel.jobId > 0)
			return;

		if (!customerModel)
			return;

		this.jobModel.primaryCustomer = customerModel;
		this.jobModel.billingIsSameAsPrimary = true;
		this.jobModel.jobName = this.jobsService.formatJobName(this.jobModel);
	}

	onBillingCustomerSelect(customerModel: ICustomerModel) {
		this.jobModel.billingIsSameAsPrimary = true;

		// Only update the job name if it's a new job
		if (!customerModel) {
			this.jobModel.billingCustomer = null;
			return;
		}

		this.jobModel.billingCustomer = customerModel;

		if (this.jobModel.primaryCustomer && this.jobModel.primaryCustomer.customerId !== this.jobModel.billingCustomer.customerId)
			this.jobModel.billingIsSameAsPrimary = false;
	}

	changeQuickbooksName() {
		this.jobModel.customQuickbooksName = true;
	}

	forceQBNameUnlock() {
		this.forceQBNameUnlockCount++;

		if (this.forceQBNameUnlockCount >= 5)
			this.canUpdateQuickbooksName = true;
	}

	async onJobStatusSelect(jobStatus: IDropdownModel) {
		await SleepService.sleep();

		// Don't let the user remove from prod if there is still equip
		if (this.jobModel.placedEquipmentCount > 0 && (jobStatus.text !== 'Production' && jobStatus.text !== 'Mitigation')) {
			await this.removeAllEquipmentConfirmRef.confirm();
			this.jobModel.jobStatusId = this.jobStatusIdTemp;
			return;
		}

		if (jobStatus)
			this.jobModel.jobStatusDescription = jobStatus.text;
		else
			this.jobModel.jobStatusDescription = null;
	}

	onEstimatorSelect(estimator: IDropdownModel) {
		if (estimator)
			this.jobModel.estimatorName = estimator.text;
		else
			this.jobModel.estimatorName = null;
	}

	onDamageTypeSelect(damageType: IDropdownModel) {
		if (damageType) {
			this.jobModel.damageTypeId = damageType.id;
			this.jobModel.damageTypeName = damageType.text;
		}
		else {
			this.jobModel.damageTypeId = null;
			this.jobModel.damageTypeName = null;
		}

		if (GlobalsService.companySettings.jobNameTemplate.indexOf("{DAMAGE_TYPE}") >= 0 || 
			GlobalsService.companySettings.jobNameTemplate.indexOf("{DAMAGE_TYPE_INITIAL}") >= 0)
			this.jobModel.jobName = this.jobsService.formatJobName(this.jobModel);

		if (!this.jobModel.syncToQuickbooks)
			this.jobModel.quickbooksName = this.jobsService.formatQuickbooksName(this.jobModel);
		
	}

	async onReferralSelect(referral: IDropdownModel) {
		if (!referral) {
			this.jobModel.referralAccountId = null;
			this.jobModel.referralContactId = null;
			this.jobModel.referralDisplayName = null;
		}
		else {
			const existingReferral = (await this.lookupService.getReferrals()).find(x => x.uuid === referral.id);
			this.jobModel.referralAccountId = existingReferral.businessDevelopmentAccountId;
			this.jobModel.referralContactId = existingReferral.businessDevelopmentContactId;
			this.jobModel.referralUuid = existingReferral.uuid;
			this.jobModel.referralDisplayName = existingReferral.displayName;
		}
	}

	onProductionManagerSelect(productionManager: IDropdownModel) {
		if (productionManager)
			this.jobModel.productionManagerName = productionManager.text;
		else
			this.jobModel.productionManagerName = null;
	}

	onSpecialtyManagerSelect(specialtyManager: IDropdownModel) {
		if (specialtyManager)
			this.jobModel.specialtyManagerName = specialtyManager.text;
		else
			this.jobModel.specialtyManagerName = null;
	}


	toggleSelfPay() {
		setTimeout(() => {
			if (this.jobModel.isSelfPay === true) {
				this.jobModel.insuranceCarrierId = null;
				this.jobModel.insuranceAdjusterId = null;
				this.jobModel.insuranceAgentId = null;
				this.jobModel.claimNumber = "Self Pay";
			}
			else {
				this.jobModel.claimNumber = '';
			}
		})
	}

	onInsuranceCarrierSelect(insuranceCarrier: IDropdownModel) {
		if (insuranceCarrier)
			this.jobModel.insuranceCarrierName = insuranceCarrier.text;
		else
			this.jobModel.insuranceCarrierName = null;
	}

	onInsuranceAdjusterSelect(insuranceAdjuster: IDropdownModel) {
		if (insuranceAdjuster)
			this.jobModel.insuranceAdjusterName = insuranceAdjuster.text;
		else
			this.jobModel.insuranceAdjusterName = null;
	}

	async onInsuranceAgentSelect(insuranceAgent: IDropdownModel) {
		if (insuranceAgent)
			this.jobModel.insuranceAgentName = insuranceAgent.text;
		else
			this.jobModel.insuranceAgentName = null;

		const agent = (await this.lookupService.getInsuranceAgents(insuranceAgent.id)).find(x => x.businessDevelopmentContactId === insuranceAgent.id);
		if (agent && agent.carrierId) {
			const carrier = this.insuranceCarriers.find(x => x.id === agent.carrierId);
			this.jobModel.insuranceCarrierId = carrier.id;
			this.jobModel.insuranceCarrierName = carrier.text;
		}			
	}

	validate(): boolean {
		this.isSubmitted = true;
		let valid = true;

		if ((!this.jobModel.primaryCustomer.contact.firstName || !this.jobModel.primaryCustomer.contact.lastName) && !this.jobModel.primaryCustomer.contact.companyName)
			valid = false;

		if (this.jobModel.jobId > 0 && !this.jobModel.primaryCustomer.customerId)
			valid = false;

		if (this.jobModel.jobStatusDescription === 'Production' || this.jobModel.jobStatusDescription === 'Mitigation') {
			if (!this.jobModel.damageTypeId) {
				Swal.fire({
					icon: 'warning',
					title: 'Missing damage type',
					text: 'Damage type must be selected before going into production',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});

				valid = false;
			}
		}


		return valid;
	}

	async validateConcurrencyIsValid(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			this.concurrencyError = await this.concurrencyValidationService.checkConcurrency(this.jobModel.jobId, ConcurrencyRecordTypes.Job, this.jobModel.version);
			if (this.concurrencyError) {
				await this.concurrencyErrorDialogRef.show("Concurrency Error", this.concurrencyError);
				resolve(false);
			}

			resolve(true);
		})
	}

	async onSaveJob(): Promise<IJobModel> {
		try {
			// Use saveJob to actually save the job!  This is just a stub method

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			// Any validation needs to go here
			if (this.validate() === false)
				return;

			if (this.jobModel.jobId > 0) {
				let concurrencyIsValid = await this.validateConcurrencyIsValid();
				if (concurrencyIsValid === false) {
					this.spinnerStatus = "error";
					return;
				}
			}

			this.jobModel = await this.saveJob(this.jobModel);
			this.spinnerStatus = "ok";
			this.onSave.emit(this.jobModel);
		}
		catch (error) {
			console.error(error);
			this.spinnerStatus = "error";
		}
	}

	async onSaveAndEmailJob(): Promise<IJobModel> {
		try {
			// Use saveJob to actually save the job!  This is just a stub method

			// Any validation needs to go here
			if (this.validate() === false)
				return;

			this.saveAndEmailSpinnerStatus = "spin";

			if (this.jobModel.jobId > 0) {
				let concurrencyIsValid = await this.validateConcurrencyIsValid();
				if (concurrencyIsValid === false) {
					this.saveAndEmailSpinnerStatus = "error";
					return;
				}
			}

			this.jobModel.primaryCustomer = this.customersService.setPrimaryCustomer(this.jobModel.primaryCustomer);
			if (!this.jobModel.billingCustomer || this.jobModel.billingCustomer.customerId === this.jobModel.primaryCustomer.customerId)
				this.jobModel.billingCustomer = null
			else
				this.jobModel.billingCustomer = this.customersService.setPrimaryCustomer(this.jobModel.billingCustomer);

			await SleepService.sleep(500);

			this.jobModel = await this.saveJob(this.jobModel);
			this.saveAndEmailSpinnerStatus = "ok";
			this.onSave.emit(this.jobModel);
		}
		catch (error) {
			console.error(error);
			this.saveAndEmailSpinnerStatus = "error";
		}
	}

	async saveJob(jobModel: IJobModel): Promise<IJobModel> {
		return this.jobsService.updateJob(jobModel);
	}

	async checkForDuplicateAddress(primaryCustomer: ICustomerModel): Promise<ICustomerModel> {
		let returnCustomer: ICustomerModel = primaryCustomer;
		if (primaryCustomer.customerId === 0) {
			const duplicateCustomerModel = await this.customersService.checkDuplicateCustomer(primaryCustomer);

			if (duplicateCustomerModel) 
				returnCustomer = await this.duplicateCustomerDialogRef.showDialog(this.jobModel.primaryCustomer, duplicateCustomerModel);			
		}

		return Promise.resolve(returnCustomer);
	}

	onCancelJob() {
		this.onCancel.emit();
	}
}
