<div *ngIf="contactModel" class="form-horizontal">
	<div *ngIf="hideFirstAndLastName === false" class="row form-group">
		<div class="col-6">
			<label>First</label>
			<input type="text" class="form-control" [slick-validation-indicator]="contactModel.firstName" slickValiationType="warning" [(ngModel)]="contactModel.firstName" />
		</div>
		<div class="col-6">
			<label>Last</label>
			<input type="text" class="form-control" [slick-validation-indicator]="contactModel.lastName" slickValiationType="warning" [(ngModel)]="contactModel.lastName" />
		</div>
	</div>

	<div *ngIf="hideCompanyName === false" class="row form-group">
		<div class="col-12">
			<label>{{companyLabel}}</label>
			<input type="text" class="form-control" [(ngModel)]="contactModel.companyName" [slick-validation-indicator]="contactModel.companyName" slickValiationType="warning" />
		</div>
	</div>
	<form-error *ngIf="invalidName && !hideFirstAndLastName && !hideCompanyName && isSubmitted === true">First and Last Name or Company Name is required</form-error>
	<form-error *ngIf="invalidName && hideFirstAndLastName && !hideCompanyName && isSubmitted === true">Company Name is required</form-error>
	<form-error *ngIf="invalidName && !hideFirstAndLastName && hideCompanyName && isSubmitted === true">First and Last Name is required</form-error>

	<div *ngIf="useQuickbooks && showQuickbooksName === true" class="row form-group">
		<div class="col-12">
			<label>Quickbooks Name</label>
			<input type="text" class="form-control" [(ngModel)]="contactModel.quickbooksName" [slick-validation-indicator]="contactModel.quickbooksName" />
		</div>
	</div>

	<div style="min-height: 8px"></div>
	<addressesEdit [(addressModels)]="contactModel.addresses"></addressesEdit>
	<div style="min-height: 8px"></div>
	<phoneNumbersEdit [(phoneNumberModels)]="contactModel.phoneNumbers"></phoneNumbersEdit>
	<div style="min-height: 8px"></div>
	<emailAddressesEdit [(emailAddressModels)]="contactModel.emailAddresses"></emailAddressesEdit>

	<div class="row" *ngIf="showButtons">
		<div class="col-12 form-save-buttons">
			<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button" (click)="onSaveContact()"><i class="far fa-save"></i>&nbsp;Save</button>
			<button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelContact()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
		</div>
	</div>
</div>

<concurrency-error-dialog #concurrencyErrorDialogRef></concurrency-error-dialog>

