import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { environment } from '../environments/environment';
import { RouterModule, PreloadAllModules } from "@angular/router";
import { StoresModule, TextChatStore } from "@stores";
import { GlobalsService, LookupService, LoginService, TasksService, HttpService, AuthService, QuickbooksService, FunctionLockService } from "@services";
import { ZiftService } from "@services/credit-cards/zift.service";
import { HttpErrorDialogComponent } from "./http-error-dialog.component";
import { VersionReleaseDialogModule } from "@shared-components/version-release-dialog"
import { SlickButtonSpinnerModule, SlickDialogModule, SlickPopoverModule, SlickInitService, SlickInitParams } from "slick-components";

import { AtatusErrorHandler } from './atatus.handler';
import { AppComponent } from './app.component';
import { AppRoutes } from "./routing";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './http.interceptor';

// Services
import { InactivityTimeoutService } from "@services/inactivity-timeout.service";
import { ThemeService } from "@components/theme.service";
// Landing
import { LandingComponent } from "./landing/landing.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
// Layout
import { LayoutModule } from "./layout/layout.module";
import { TextChatHubService } from '../../../libraries/signalr/text-chat-hub.service';
import { MessageQueueDispatcher, MessageQueueHubService } from "@message-queue";
import { DevTraceService } from '../../../libraries/services/utils/dev-trace.service';

@NgModule({
	declarations: [
		AppComponent,				
		LandingComponent,
		PageNotFoundComponent,
		HttpErrorDialogComponent		
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		FormsModule,
		LayoutModule,
		StoresModule,
		VersionReleaseDialogModule,
		SlickButtonSpinnerModule, SlickDialogModule, SlickPopoverModule,
		RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules })
	],
	providers: [
		InactivityTimeoutService,
		LoginService, TasksService,
		AuthService, HttpService, DevTraceService, LookupService, FunctionLockService, QuickbooksService, ZiftService,
		GlobalsService, ThemeService, MessageQueueHubService, TextChatHubService, MessageQueueDispatcher,
		{ 
			provide: LocationStrategy, 
			useClass: HashLocationStrategy 
		},
		{
			provide: ErrorHandler,
			useClass: AtatusErrorHandler
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: init,
			deps: [GlobalsService, LookupService, ThemeService, MessageQueueHubService, TextChatStore, TextChatHubService],
			multi: true
		}],
	bootstrap: [AppComponent]
})
export class AppModule {}

export function init(globalsService: GlobalsService, lookupService: LookupService, themeService: ThemeService, messageQueueHubService: MessageQueueHubService, textChatStore: TextChatStore, textChatHub: TextChatHubService) {
	return async () => {
		const slickInitParams = new SlickInitParams();
		SlickInitService.init(slickInitParams);

		if (environment.staging === true)
			globalsService.setStaging();
		else if (environment.rc === true)
			globalsService.setRC();
		else if (environment.production === false)
			globalsService.setDebug();

		if (window.location.host === 'payments.jobsight.com' ||
			window.location.host === 'devpayments.jobsight.com' ||		
			window.location.host === 'rcpayments.jobsight.com' ||
			window.location.host === 'stagingpayments.jobsight.com') {
			GlobalsService.isLoggedIn = false;
			GlobalsService.isOnlinePayments = true;
			await themeService.loadTheme("default")
			return;
		}
		
		const isLoggedIn = await globalsService.isLoggedIn();

		if (window.location.href.indexOf("/customer-forms") >= 0) {
			GlobalsService.isLoggedIn = false;
			await themeService.loadTheme("default")
			return;
		}

		if (window.location.href.indexOf("#/survey") >= 0) {
			GlobalsService.isLoggedIn = false;
			await themeService.loadTheme("default")
			return;
		}

		if (isLoggedIn === false && window.location.href.indexOf("/login") >= 0) {
			window.location.href = "/#/login";
        }

		if (isLoggedIn === false && window.location.href.indexOf("/forgotPassword") >= 0) {
			await themeService.loadTheme("default")
			return;
		}

		if (isLoggedIn === false && window.location.href.indexOf("/resetPassword") >= 0) {
			await themeService.loadTheme("default")
			return;
		}

		if (!isLoggedIn) {
			await themeService.loadTheme("default")
			return;
		}

		await globalsService.getCacheChecksums();

		await globalsService.init(false);
		await themeService.loadTheme(GlobalsService.userSettings.theme || "Default");

		messageQueueHubService.init();

		if (GlobalsService.companySettings.useTextChat) {
			textChatHub.init();
			await textChatStore.init(false);
		}
	}
}

